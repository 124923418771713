import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import {
  Envelope,
  EnvelopeCheck,
  EnvelopePlus,
  PersonCircle,
  PinMap,
} from "react-bootstrap-icons"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import Seo from "../components/Seo"
import { ExternalLink } from "../components/ui/ExternalLink"

export const Head = () => {
  return <Seo customTitle="Kontakt" />
}

export default function Kontakt() {
  const siteData = useStaticQuery(graphql`
    query contactEmail {
      site {
        siteMetadata {
          contactPersons {
            person
            title
          }
          email
        }
      }
    }
  `)

  const personList = siteData.site.siteMetadata.contactPersons
  const email = siteData.site.siteMetadata.email

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // Copy email address to clipboard
  const copyEmail = () => {
    navigator.clipboard.writeText(email)
    handleShow()
  }

  return (
    <Layout>
      <Container className="my-5">
        <PageTitle title="Kontakt" />
        <p className="lh-text mb-5 lead font-weight-normal">
          Sie haben Fragen, Wünsche oder Anregungen? Kontaktieren Sie uns bitte
          über die unten hinterlegte E-Mail Adresse.
        </p>

        <address className="my-5 lead font-weight-normal border shadow-sm p-3 p-lg-5 rounded text-center text-lg-left">
          <Row className="mb-5">
            <Col>
              <PinMap className="display-4 text-dark mb-3 mb-md-0" />
            </Col>
            <Col md={10} className="d-flex flex-column">
              <span className="font-weight-bold">
                MotorSportFreunde Plettenberg 1930 e.V. im DMV
              </span>
              <span>Wiesenstraße 8</span>
              <span>58840 Plettenberg</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Envelope className="display-4 text-dark mb-3 mb-md-0" />
            </Col>
            <Col md={10}>
              <ExternalLink href={`mailto:${email}`}>{email}</ExternalLink>
              <Button
                className="my-4 d-flex align-items-center mx-auto mx-lg-0"
                variant="outline-secondary"
                onClick={() => copyEmail()}
              >
                <EnvelopePlus className="mr-2" />
                E-Mail Adresse kopieren...
              </Button>
            </Col>
          </Row>
        </address>

        <Row xs={1} md={3} lg={4}>
          {personList.map((entry, i) => (
            <Col className="mb-3" key={i}>
              <Card className="h-100 shadow-sm">
                <Card.Body className="text-center">
                  <PersonCircle className="text-primary display-2 mb-3" />
                  <Card.Title>{entry.person}</Card.Title>
                  <Card.Subtitle className="text-dark">
                    {entry.title}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <EnvelopeCheck className="mr-2" />
            E-Mail Adresse wurde kopiert!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="lh-text">
          Die E-Mail Adresse wurde in die Zwischenablage kopiert und kann nun,
          zum Beispiel auf Desktop-PCs, mit der Tastenkombination{" "}
          <kbd>STRG+V</kbd> in andere Programme oder Dateien eingefügt werden.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}
